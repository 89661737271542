import React, { useEffect, useState } from "react";
import { Card, List } from 'antd';
import { NavLink } from "react-router-dom";
import { api } from "src/services";
import Meta from "antd/lib/card/Meta";
import { ArrowRightOutlined } from "@ant-design/icons";

const PostCategory = ({
  index,
  category
}) => {

  const [postData, setPostData] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchPostByCategories = async () =>{
    try {
      setLoading(true);
      const { data }:any = await api.getPostByCategoryies(category.id);
      if (data && data.length > 0) {
        setPostData(data[0].posts);
      }
    } catch (error) {
      console.error(error)
    }
    setLoading(false);
  }

  useEffect(()=>{
    fetchPostByCategories()
  },[])

  return (
    <div className="list-type-news">
      <div className="news" key={index}>
        <NavLink to={`/news-category/${category.id}`}>
          <h1>{category.name}</h1>
        </NavLink>
        <List 
          className="ant-news-list"
          dataSource={postData}
          loading={loading}
          renderItem={(item:any, index:any)=>(
            <NavLink to={`/news/${item.id}`} key={index}>
              <Card key={index} cover={<img alt={item.name} src={item.image} />}>
                <div className="fs-18 IBMPlexSans-Bold">{item.name}</div>
              </Card>
            </NavLink>
          )}
          
        />
        <div className="news-link">
          <NavLink to={`/news-category/${category.id}`}>
            Xem tất cả
            <ArrowRightOutlined />
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default PostCategory;
