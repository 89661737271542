import React, { useEffect, useState } from "react";
import { Card, List, Pagination } from 'antd';
import { NavLink } from "react-router-dom";
import { api } from "src/services";
import NewsList from "src/components/News/NewsList";

  const NewsCategory = (props) => {
 /* INIT STATE */
  const [categoryData, setCategoryData] = useState(null);
  const [data, setData] = useState([]);
  const [curentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const categoryId = props.match.params.id;

  const fetchPostByCategories = async () =>{
    try {
      setLoading(true);
      const { data: rdata }:any = await api.getPostByCategory(categoryId, curentPage);
      if (rdata && rdata.length > 0) {
        setCategoryData(rdata[1]);
        setData(rdata[1]?.posts);
      }
    } catch (error) {
      console.error(error)
    }
    setLoading(false);
  }

  useEffect(()=>{
    fetchPostByCategories()
  },[curentPage])

  return (
    <div className="list-type-news">
      <div className="news">
        <h1>{categoryData?.categories?.name}</h1>
        <NewsList />
        <List
          className="ant-news-list"
          dataSource={data}
          loading={loading}
          renderItem={(item:any, index:any)=>(
            <NavLink to={`/news/${item.id}`} key={index}>
              <Card key={index} cover={<img alt={item.name} src={item.image} />}>
                <div className="fs-18 IBMPlexSans-Bold">{item.name}</div>
              </Card>
            </NavLink>
          )}
        />
        {categoryData ? 
                <div className="pagination-wrapper">
                <Pagination
                  current={curentPage}
                  defaultPageSize={categoryData.meta.per_page}
                  pageSize={categoryData.meta.per_page}
                  defaultCurrent={1}
                  onChange={(page, pageSize)=> setCurrentPage(page) }
                  total={categoryData.meta.total} />
              </div>
        : ''}
      </div>
    </div>
  );
};

export default NewsCategory;
