import {
  Button,
  Card,
  Checkbox,
  Col,
  Collapse,
  Form,
  Input,
  Modal,
  Radio,
  Row,
  Select,
  Skeleton,
  message,
} from "antd";
import { useEffect, useState } from "react";
import { api } from "src/services";
import { PacketTestType, resultCheckType } from "src/types";
import {
  PersonalInfo,
  TestTypeButtons,
  UploadSection,
} from "src/components";
import moment from "moment";
import { CloseOutlined } from "@ant-design/icons";
import { SampleOrderTestModal } from "src/components";
import { isEmpty } from "lodash";
import { utils } from "src/utils";
const { Option } = Select;
function OrderByTestModal({ id, handleCancel, fetchData, random }) {
  const [patientInfo, setPatientInfo]: any = useState([]);
  const [packetTests, setPacketTests] = useState<PacketTestType[]>([]);
  const [samplingPackages, setSamplingPackages]: any = useState([]);
  const [isImageConfirmationNeeded, setIsImageConfirmationNeeded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingOrdertest, setLoadingOrdertest] = useState(false);
  const [formRef] = Form.useForm();
  const [selectedTests, setSelectedTests] = useState([]);
  const [fileUploads, setFileUploads] = useState([]);
  const [numberOfCheckedFiles, setNumberOfCheckedFiles] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [typeTextErrorMessage, setTypeTextErrorMessage] = useState("");
  const formData = new FormData();
  const [searchQuery, setSearchQuery]: any = useState("");
  const [currentTime] = useState(moment().format("YYYY-MM-DD"));
  const [resultCheck, setResultCheck] = useState<resultCheckType[]>([]);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filterValue, setFilterValue] = useState("");
  const [openModalSampleOrder, setOpenModalSampleOrder] = useState(false);
  const [selectedPackageId, setSelectedPackageId] = useState(null);
  const [selectedSamplingId, setSelectedSamplingId] = useState(null);
  const [sampleOrderDoctor, setSampleOrderDoctor] = useState([]);
  const [totalAll, setTotalPrice] = useState(0);

  const fetchMyPatient = async () => {
    try {
      setIsLoading(true);
      const { data: rData }: any = await api.getMemberInfo(id);
      if (rData) {
        setPatientInfo(rData);
        formRef.setFieldsValue({
          member_name: rData.name,
          member_gender: rData.gender,
          member_dob: rData.dob,
          member_phone: rData.phone,
          member_email: rData.email,
          short_address: rData.short_address,
          province_id: rData.province.matp,
          district_id: rData.district.maqh,
          ward_id: rData.ward.xaid,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleCancelModal = () => {
    setOpenModalSampleOrder(false);
  };
  const fetchTestPriority = async () => {
    try {
      const { data: rData }: { data: PacketTestType[] } = await api.getTestPriority();
      if (rData) {
        if (!selectedPackageId) {
          let packageDefault = rData.find((item) => item?.is_default === 1);
          setTotalPrice(prevTotalPrice => prevTotalPrice + packageDefault?.price);
          setSelectedPackageId(packageDefault?.id);
        }
        setPacketTests(rData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchTestPlace = async () => {
    try {
      const { data: rData }: { data: PacketTestType[] } = await api.getTestPlace();
      if (rData) {
        if (!selectedSamplingId) {
          let samplingDefault = rData.find((item) => item?.is_default === 1);
          setTotalPrice(prevTotalPrice => prevTotalPrice + samplingDefault?.price);
          setSelectedSamplingId(samplingDefault?.id);
        }
        setSamplingPackages(rData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchTestCategories = async () => {
    try {
      const { data: rData }: any = await api.getAllTestCategories(searchQuery);
      if (rData) {
        setData(rData);
        setFilteredData(rData);
      }
    } catch (error) {
      message.error(error);
    }
  };

  useEffect(() => {
    fetchTestPriority();
    fetchTestPlace();
    fetchMyPatient();
    fetchSampleOrderTest();
  }, [id, random]);

  useEffect(() => {
    setErrorMessage(null);
    setNumberOfCheckedFiles(0);
    setFileUploads([]);
    fetchTestCategories();
  }, [random]);

  function totalTypeText(values) {
    const fieldsToAppend = {
      patient_id: id,
      member_address: patientInfo?.address,
      member_dob: patientInfo?.dob,
      member_email: patientInfo?.email,
      member_gender: patientInfo?.gender,
      member_name: patientInfo?.name,
      member_phone: patientInfo.phone,
      province_id: patientInfo?.province?.matp,
      district_id: patientInfo?.district?.maqh,
      ward_id: patientInfo?.ward?.xaid,
      short_address: patientInfo?.short_address,
      packet_test_id: selectedPackageId,
      sampling_package_id: selectedSamplingId,
      total: totalAll,
    };

    for (const [field, fieldValue] of Object.entries(fieldsToAppend)) {
      if (fieldValue !== undefined) {
        formData.append(field, fieldValue);
      }
    }
    return formData;
  }
  const handleModalSampleOrder = async () => {
    setOpenModalSampleOrder(true);
  };

  //  Submit form
  const handleSubmit = async (values) => {
    if (!isImageConfirmationNeeded) {
      if (resultCheck.length <= 0) {
        setTypeTextErrorMessage("Vui lòng chọn ít nhất một loại xét nghiệm");
        return;
      }
      selectedTests.forEach((type_test, index) => {
        formData.append(`type_test[${index}]`, type_test);
      });
      setFileUploads(null);
    } else if (numberOfCheckedFiles < 1) {
      setErrorMessage("Vui lòng chọn ít nhất một hình ảnh xét nghiệm");
      return;
    } else {
      fileUploads.forEach((file, index) => {
        formData.append(`images[${index}]`, file.file);
      });
      values.member_gender = "";
      values.member_dob = currentTime;
      values.member_email = "";
    }
    totalTypeText(values);
    try {
      setIsLoading(true);
      const response: any = await api.createOrderTest(formData);
      if (response.error) {
        message.error(response.message);
      } else {
        message.success(response.message);
      }
    } catch (error) {
      console.log(error);
    } finally {
      handleCancel();
      fetchData();
      setSelectedPackageId(selectedPackageId);
      setSelectedSamplingId(selectedSamplingId);
      setSelectedTests([]);
      setIsLoading(false);
      setResultCheck([]);
      fetchSampleOrderTest();
      let packetPrice = packetTests.find(el => el?.id === selectedPackageId)?.price;
      let samplePrice = samplingPackages.find(el => el?.id === selectedSamplingId)?.price;
      setTotalPrice(packetPrice + samplePrice);
    }
  };

  // up load hình
  const onBeforeUpload = (file) => {
    if (file) {
      setErrorMessage(null);
    }
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG files!");
      return false;
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must be smaller than 2MB!");
      return false;
    }

    const fileObject = {
      name: file.name,
      size: file.size,
      url: null,
      file: file,
    };
    setFileUploads((prevFiles) => prevFiles.concat(fileObject));
    getBase64(file, (url) => {
      fileObject.url = url;
    });
    return false;
  };

  //  get base 64
  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  // search
  const handleSearch = () => {
    const filteredResults = data.filter((parent) => {
      const matchedChildren = parent.type_tests.filter((child) =>
        child.name.toLowerCase().includes(filterValue.toLowerCase())
      );
      return matchedChildren.length > 0;
    });
    setFilteredData(filteredResults);
  };
  useEffect(() => {
    handleSearch();
  }, [filterValue]);

  const onRemove = (file) => {
    const updatedFiles = fileUploads.filter((item) => item.name !== file.name);
    setFileUploads(updatedFiles);
  };

  const handleCheckboxChange = (checkedValues) => {
    const isChecked = selectedTests.includes(checkedValues.id);
    if (isChecked) {
      const removerItem = resultCheck.filter((item) => Number(item.id) !== Number(checkedValues.id));
      setTotalPrice(totalAll - checkedValues?.price);
      setResultCheck(removerItem);
    } else {
      setTotalPrice(checkedValues?.price + totalAll);
      setResultCheck([...resultCheck, checkedValues]);
    }
    const newSelected = isChecked ? selectedTests.filter((selectedTest) => selectedTest !== Number(checkedValues.id)) : [...selectedTests, Number(checkedValues.id)];
    setSelectedTests(newSelected);
    setTypeTextErrorMessage("");
  };

  const handleRemove = (e) => {
    const removerItem = resultCheck.filter((item, i) => Number(item.id) !== e);
    let testPrice = packetTests.find((value) => Number(value.id) === Number(selectedPackageId))?.price;
    let samplingPrice = samplingPackages.find((value) => Number(value.id) === Number(selectedSamplingId))?.price;
    setTotalPrice(removerItem.reduce((accumulator, item) => accumulator + item?.price, 0) + testPrice + samplingPrice);
    setSelectedTests(removerItem.map((el: any) => Number(el.id)));
    setResultCheck(removerItem);
  };

  const handleRadioChange = (item) => {
    let packagePrev = packetTests.find(e => e?.id === selectedPackageId);
    setTotalPrice(totalAll - packagePrev?.price + item?.price)
    setSelectedPackageId(item?.id);
  };

  const handleSamplingRadioChange = (item) => {
    let samplePrev = samplingPackages.find(e => e?.id === selectedSamplingId);
    setTotalPrice(totalAll - samplePrev?.price + item?.price)
    setSelectedSamplingId(item?.id);
  };

  const onChangeSampleTest = (event) => {
    if (!event) {
      return;
    }

    const sampleTestSelected = sampleOrderDoctor.find(el => el?.id === event);
    setSelectedPackageId(sampleTestSelected?.packet_test_id);
    setSelectedSamplingId(sampleTestSelected?.sampling_package_id);
    const arrayId = sampleTestSelected?.type_tests.map((el: any) => Number(el.id));
    setSelectedTests(arrayId);
    setResultCheck(sampleTestSelected?.type_tests);

    // Tính tổng tiền
    let typeTestPrice = sampleTestSelected?.type_tests.reduce((accumulator, item) => accumulator + item?.price, 0);
    let testPrice = packetTests.find((value) => Number(value.id) === Number(sampleTestSelected?.packet_test_id))?.price;
    let samplingPrice = samplingPackages.find((value) => Number(value.id) === Number(sampleTestSelected?.sampling_package_id))?.price;
    setTotalPrice(typeTestPrice + testPrice + samplingPrice);
  };

  // Danh sách yêu cầu xét nghiệm mẫu
  const fetchSampleOrderTest = async () => {
    try {
      const res: any = await api.getSampleOrderTestByDoctor();
      if (res && res.data) {
        setSampleOrderDoctor(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const { Panel } = Collapse;
  return (
    <div className="admin-table OrderTest">
      <div className="select_sample_order_test">
        <h4>Chọn yêu cầu xét nghiệm mẫu</h4>
        <Select
          showSearch
          placeholder="Chọn yêu cầu xét nghiệm mẫu"
          onChange={(event) => onChangeSampleTest(event)}
          style={{ width: "100%" }}
        >
          <option key={0} value="">Chọn Chỉ định mẫu</option>
          {sampleOrderDoctor.map((sampleOrder) => (
            <Option key={sampleOrder.id} value={sampleOrder.id}>{sampleOrder.name}</Option>
          ))}
        </Select>
      </div>
      <div className="flex-fill">
        <Form form={formRef} onFinish={handleSubmit}>
          <Row gutter={[16, 16]}>
            <Col span={15}>
              <Card
                className="card-order-left"
                style={{
                  marginBottom: "1rem",
                }}
              >
                <div className="order-content order-content_wapper package_test_wrapper">
                  <div className="packet_test">
                    {packetTests.length > 0 && (
                      <>
                        <div style={{ marginBottom: '10px' }}>
                          <strong>Gói xét nghiệm: </strong>
                        </div>
                        <Radio.Group
                          defaultValue={selectedPackageId}
                          buttonStyle="solid"
                          value={selectedPackageId}
                        >
                          {packetTests.map((item) => (
                            <Radio.Button
                              key={item.id}
                              value={item.id}
                              style={{
                                marginBottom: "1rem",
                                width: "100%",
                                background: selectedPackageId == item.id ? item.color : "initial",
                              }}
                              onClick={() => handleRadioChange(item)}
                            >
                              {item.name} - {item.price_formated}
                            </Radio.Button>
                          ))}
                        </Radio.Group>
                      </>
                    )}
                  </div>
                  <div className="location_test">
                    {samplingPackages.length > 0 && (
                      <>
                        <div style={{ marginBottom: '10px' }}>
                          <strong>Xét nghiệm tại: </strong>
                        </div>
                        <Radio.Group
                          defaultValue={selectedSamplingId}
                          buttonStyle="solid"
                          value={selectedSamplingId}
                        >
                          {samplingPackages.map((item) => (
                            <Radio.Button
                              key={item.id}
                              value={item.id}
                              style={{
                                marginBottom: "1rem",
                                width: "100%",
                                background: selectedSamplingId == item.id ? item.color : "initial",
                              }}
                              onClick={() => handleSamplingRadioChange(item)}
                            >
                              {item.name} - {item.price_formated}
                            </Radio.Button>
                          ))}
                        </Radio.Group>
                      </>
                    )}
                  </div>
                </div>
              </Card>

              <Card
                style={{
                  marginBottom: 16,
                  height: "unset",
                }}
                loading={isLoading}
              >
                <TestTypeButtons
                  isImageConfirmationNeeded={isImageConfirmationNeeded}
                  setIsImageConfirmationNeeded={setIsImageConfirmationNeeded}
                  setSearchQuery={setSearchQuery}
                  setLoadingOrdertest={setLoadingOrdertest}
                />
                <PersonalInfo patientInfo={patientInfo} />
                <UploadSection
                  isImageConfirmationNeeded={isImageConfirmationNeeded}
                  onBeforeUpload={onBeforeUpload}
                  setCheckFile={setNumberOfCheckedFiles}
                  messageError={errorMessage}
                  onRemove={onRemove}
                />
              </Card>
              <Card
                size="small"
                className="call_to_action"
                style={{
                  marginTop: "1rem",
                }}
              >
                <div className="totalPrice">
                  <h4>Tổng tiền:</h4>
                  <span>{utils.formatMoney(totalAll)} đ</span>
                </div>
                <div className="wp_btn">
                  <Form.Item>
                    <Button type="primary" loading={isLoading} htmlType="submit">
                      Lưu
                    </Button>
                  </Form.Item>
                  <Form.Item>
                    <Button
                      loading={isLoading}
                      type="primary"
                      onClick={handleModalSampleOrder}
                    >
                      Lưu yêu cầu Xét Nghiệm Mẫu
                    </Button>
                  </Form.Item>
                </div>
                <Modal
                  title="Nhập Tên Cho Yêu Cầu Xét Nghiệm Mẫu (Ví dụ: Yêu cầu xét nghiệm cho bệnh Tim)"
                  open={openModalSampleOrder}
                  footer={null}
                  onCancel={() => setOpenModalSampleOrder(false)}
                  width={800}
                  className="modal-scroll"
                  centered
                >
                  <SampleOrderTestModal
                    handleCancelModal={handleCancelModal}
                    resultChecked={resultCheck}
                    packetItem={selectedPackageId}
                    sampling={selectedSamplingId}
                    fetchSampleOrderTest={fetchSampleOrderTest}
                  />
                </Modal>
              </Card>
            </Col>
            <Col span={9}>
              <Card
                title="Loại xét nghiệm"
                style={{
                  marginBottom: 16,
                  height: "unset",
                }}
                size="small"
              >
                <Form.Item>
                  <Input
                    placeholder="Tìm kiếm danh mục"
                    onChange={(e) => setFilterValue(e.target.value)}
                  />
                </Form.Item>

                <Form.Item>
                  <Skeleton loading={loadingOrdertest} active>
                    <div className="scroll-checkbox">
                      <Collapse>
                        {filteredData.map((cat, index) => (
                          <Panel header={cat.name} key={index}>
                            <Row>
                              {cat.type_tests.map((test) => (
                                <Col
                                  key={test.id}
                                  span={24}
                                  style={{ marginBottom: 5 }}
                                >
                                  <Checkbox
                                    checked={selectedTests.some(
                                      (item) => item === test.id
                                    )}
                                    onClick={() => handleCheckboxChange(test)}
                                  >
                                    {`${test.name
                                      } - ${test.price.toLocaleString(
                                        "vi-VN"
                                      )} VNĐ`}
                                  </Checkbox>
                                </Col>
                              ))}
                            </Row>
                          </Panel>
                        ))}
                      </Collapse>
                    </div>
                  </Skeleton>
                  <label style={{ color: "red" }}>
                    {!isEmpty(typeTextErrorMessage) ? typeTextErrorMessage : ""}{" "}
                  </label>
                </Form.Item>
              </Card>
              <Card style={{ height: "unset", marginBottom: "1rem" }}>
                <div
                  style={{
                    fontWeight: "bold",
                    marginBottom: "1rem",
                  }}
                >
                  Loại xét nghiệm được chọn
                </div>
                <div>
                  <ul
                    style={{
                      maxHeight: "100px",
                      overflow: "auto",
                    }}
                  >
                    {resultCheck.map((test, index) => (
                      <li className="result__test-type" key={Number(test.id)}>
                        <span
                          onClick={() => handleRemove(Number(test.id))}
                          className="result__test-type-icon"
                        >
                          <CloseOutlined />
                        </span>
                        {`${test.name} - ${Number(test.price).toLocaleString(
                          "vi-VN"
                        )} VNĐ`}
                      </li>
                    ))}
                  </ul>
                </div>
              </Card>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
}

export default OrderByTestModal;
