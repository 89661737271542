import { UserOutlined } from '@ant-design/icons'
import { Avatar, Input, List } from 'antd'
import moment from 'moment';
import React, { useState } from 'react'

export default function ListChat({
    dataSource,
    adminChat,
    loading,
    userId,
    openChat
}) {
    /*  INIT VARIABLE  */
    const data = dataSource;
    const [searchUser, setSearchUser] = useState('');
    
    /* HELPER FUNCTION */

    /**
     * Lấy dữ liệu người dùng từ members để tách người dùng chính và đối tượng chat
     * @param data 
     */
    const getUserTargetChat = ({ members }) =>{
        if(!members) return {};

        if(members[0]._id === userId){
            return members[1];
        }else {
            return members[0];
        }
    }

    const getTargetChatAvatar = (data:any) =>{
        const user:any = getUserTargetChat(data);
        return user.avatar;
    }

    const getTargetChatName = (data:any) =>{
        const user:any = getUserTargetChat(data);
        return user.name;
    }

    const getLastMessage = ({lastMessage}) =>{
        if(!lastMessage?.user) return "";

        if(userId === lastMessage.user._id){
            return `Bạn: ${lastMessage.text}`;
        }
        return lastMessage.text;
    }

    const getLastTimeSeen = ({lastMessage}) =>{
        if(!lastMessage?.createdAt) return "";

        let timeAgo = moment(lastMessage.createdAt).fromNow();

        return timeAgo;
    }

    const makeUpData = () =>{
        const newData:Object[] | null = data?.filter((item:any)=> getTargetChatName(item).includes(searchUser))
        return newData.length > 0 ? newData : [];
    }
    const isRead = ({is_read}) =>{
        return is_read === "1";
    }
    return (
        <div className='list-chat-wrapper'>
            <div className='mb-12'>
                <Input 
                    placeholder="Tìm kiếm"
                    value={searchUser}
                    onChange={(event)=> setSearchUser(event.target.value) }
                    prefix={<UserOutlined />} 
                />
            </div>

            
            { adminChat ? 
                <div className='chat-item' style={{ cursor: "pointer" }}
                    onClick={()=> openChat( adminChat.id, <div className='header-message'> <Avatar src={getTargetChatAvatar(adminChat)}/> {getTargetChatName(adminChat)}</div>)}>
                    <List.Item style={{ width: "100%" }}>
                        <Avatar src={getTargetChatAvatar(adminChat)} />
                        <div className='chat-box'>
                            <div className="">
                                <div className="overflow-ellipsis">
                                    <strong >{getTargetChatName(adminChat)}</strong>
                                </div>
                                <div className="overflow-ellipsis">
                                    { 
                                        isRead(adminChat)  ? <span>{getLastMessage(adminChat)}</span>
                                        : <strong>{getLastMessage(adminChat)}</strong>
                                    }
                                </div>
                            </div>
                            <div className='time-group'>
                                { 
                                    isRead(adminChat) ? <span>{getLastTimeSeen(adminChat)}</span>
                                    : <>
                                        <span className='dot'></span>
                                        <strong>{getLastTimeSeen(adminChat)}</strong>
                                    </>
                                }
                            </div>
                        </div>
                    </List.Item>
                </div> : <></>}
                

            <List dataSource={makeUpData()}
                loading={loading}
                renderItem={(item: any) => (
                    <div className='chat-item' style={{ cursor: "pointer" }}
                        onClick={()=> openChat( item.id, <div className='header-message'> <Avatar src={getTargetChatAvatar(item)}/> {getTargetChatName(item)}</div>)}>
                        <List.Item style={{ width: "100%" }}>
                            <Avatar src={getTargetChatAvatar(item)} />
                            <div className='chat-box'>
                                <div className="">
                                    <div className="overflow-ellipsis">
                                        <strong >{getTargetChatName(item)}</strong>
                                    </div>
                                    <div className="overflow-ellipsis">
                                        { 
                                            isRead(item)  ? <span>{getLastMessage(item)}</span>
                                            : <strong>{getLastMessage(item)}</strong>
                                        }
                                    </div>
                                </div>
                                <div className='time-group'>
                                    { 
                                        isRead(item) ? <span>{getLastTimeSeen(item)}</span>
                                        : <>
                                            <span className='dot'></span>
                                            <strong>{getLastTimeSeen(item)}</strong>
                                        </>
                                    }
                                </div>
                            </div>
                        </List.Item>
                    </div>
                )}
            />
        </div>
    )
}
