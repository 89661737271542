import {
  Card,
  Col,
  Empty,
  Form,
  List,
  Modal,
  Pagination,
  Row,
  Skeleton,
  message,
} from "antd";
import { useEffect, useState } from "react";
import { api } from "src/services";
import { OrderByTestModal } from "src/components";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import {
  IconViewDetail,
  IconAdd,
  ModalDelete,
  IconDelete,
} from "src/components";

const ListOrderByTest = ({ patient_id }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData]: any = useState([]);
  const [imageOrderTest, setImageOrderTest]: any = useState({});
  const [filePDF, setFilePDF]: any = useState([]);
  const [openModalDetail, setOpenModalDetail] = useState(false);
  const [openModalOrderByTest, setOpenModalOrderByTest] = useState(false);
  const [random, setRandom] = useState(Math.random());
  const [openModalDeleteOrderByTest, setOpenModalDeleteOrderByTest] =
    useState(false);
  const [formDelete] = Form.useForm();
  const [current, setCurent]: any = useState(1);
  const [pageSize, setPageSize]: any = useState(10);
  const [total, setTotal]: any = useState(10);

  const fetchData = async (page = 1, pageSize = 10) => {
    try {
      setLoading(true);
      const { data: rData, meta }: any = await api.getAllTestByIdPatient(
        patient_id,
        page,
        pageSize
      );
      if (rData) {
        setTotal(meta.total);
        setData(rData);
      } else {
        setData([]);
        setTotal(0);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(current, pageSize);
  }, [patient_id, current, pageSize]);

  const handleCancel = () => {
    setOpenModalOrderByTest(false);
    setOpenModalDeleteOrderByTest(false);
  };

  const handleDelete = async (values) => {
    try {
      setLoading(true);
      const response: any = await api.deleteOrderPatient(values.id);
      if (response.error) {
        message.error(response.message);
      } else {
        message.success(response.message);
      }
    } catch (error) {
      console.log(error);
      message.error(error.message);
    } finally {
      setLoading(false);
      handleCancel();
      fetchData();
    }
  };

  function srcset(image: string, size: number, rows = 1, cols = 1) {
    return {
      src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
      srcSet: `${image}?w=${size * cols}&h=${
        size * rows
      }&fit=crop&auto=format&dpr=2 2x`,
    };
  }

  return (
    <>
      <List
        locale={{
          emptyText: (
            <Empty description="Không tìm thấy phiếu yêu cầu xét nghiệm" />
          ),
        }}
        loading={loading}
        header={
          <>
            <div className="font-bold">Phiếu yêu cầu xét nghiệm</div>
            {
              patient_id &&
              <div
                title="Thêm yêu cầu xét nghiệm"
                onClick={() => {
                  setOpenModalOrderByTest(true);
                  setRandom(Math.random());
                }}
              >
                <span className="font-bold" style={{ cursor: "pointer" }}>Tạo mới</span>
              </div>
            }
          </>
        }
        size="small"
        className="list-order-test bg-white"
        bordered
        dataSource={data}
        footer={
          <>
            {total > 10 ? (
              <Pagination
                style={{ float: "right" }}
                size="small"
                defaultCurrent={1}
                total={total}
                current={current}
                defaultPageSize={10}
                pageSize={pageSize}
                onChange={(current, pageSize) => {
                  setCurent(current);
                  setPageSize(pageSize);
                }}
              />
            ) : (
              ""
            )}
          </>
        }
        renderItem={(item: any, index) => (
          <>
            <List.Item
              key={item?.id}
              onClick={() => {
                setOpenModalDetail(true);
                setImageOrderTest(item.images);
                setFilePDF(item.file_pdf);
              }}
              actions={[
                // <div>
                //   <span>{item?.total}</span>
                // </div>,
                <IconDelete
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpenModalDeleteOrderByTest(true);
                    formDelete.setFieldValue("id", item.id);
                  }}
                />,
                <IconViewDetail
                  onClick={() => {
                    setOpenModalDetail(true);
                    setImageOrderTest(item.images);
                    setFilePDF(item.file_pdf);
                  }}
                />,
              ]}
            >
              <List.Item.Meta
                title={
                  <div className="patient-booking-name">
                    <span> {item?.packet}</span>
                    <div className="patient-booking-name-time">
                      <span>{item?.total}</span>
                    </div>
                  </div>
                }
                description={
                  <Row className="patient-booking-detail">
                    <Col
                      key={1}
                      span={24}
                      className="patient-booking-detail-info"
                    >
                      <Row>
                        {Array.isArray(item?.type_tests) ? (
                          item.type_tests.map((el) => (
                            <Col span={12} key={el.name}>
                              {/* Added key */}
                              <span className="patient-booking-detail-info-text">
                                {el.name}
                              </span>
                            </Col>
                          ))
                        ) : (
                          <Col span={12}>
                            <span className="patient-booking-detail-info-text">
                              Yêu cầu bằng phiếu xét nghiệm
                            </span>
                          </Col>
                        )}
                      </Row>
                    </Col>
                    {/* <Col span={2}>
                      <Row>
                        <Col span={12}>
                          <IconViewDetail
                            onClick={() => {
                              setOpenModalDetail(true);
                              setImageOrderTest(item.images);
                              setFilePDF(item.file_pdf);
                            }}
                          />
                        </Col>
                        <Col span={12}>
                          <IconDelete
                            onClick={(e) => {
                              e.stopPropagation();
                              setOpenModalDeleteOrderByTest(true);
                              formDelete.setFieldValue("id", item.id);
                            }}
                          />
                        </Col>
                      </Row>
                    </Col> */}
                  </Row>
                }
              />
            </List.Item>
            {/* {index === data.length - 1 && (
              <Pagination
                style={{ float: "right" }}
                size="small"
                defaultCurrent={1}
                total={total}
                current={current}
                defaultPageSize={10}
                pageSize={pageSize}
                onChange={(current, pageSize) => {
                  setCurent(current);
                  setPageSize(pageSize);
                }}
              />
            )} */}
          </>
        )}
      />
      <Modal
        title="Phiếu yêu cầu xét nghiệm chi tiết"
        centered
        open={openModalDetail}
        footer={null}
        onCancel={() => setOpenModalDetail(false)}
        width={1000}
        className="modal-scroll"
      >
        {imageOrderTest.length > 0 ? (
          <Card
            size="small"
            style={{
              marginBottom: 16,
              height: "unset",
            }}
            title="Ảnh:"
          >
            <Skeleton loading={loading}>
              <div>
                <ImageList
                  variant="quilted"
                  cols={Math.min(imageOrderTest.length, 5)}
                  gap={16}
                  rowHeight={
                    imageOrderTest.length === 4
                      ? 150
                      : imageOrderTest.length === 3
                      ? 200
                      : imageOrderTest.length === 2
                      ? 250
                      : imageOrderTest.length === 1
                      ? 500
                      : 121
                  }
                >
                  {imageOrderTest.map((item: any) => (
                    <div>
                      {item ? (
                        <ImageListItem
                          key={item.url}
                          cols={item.cols || 1}
                          rows={item.rows || 1}
                          className="list-images"
                        >
                          <img
                            {...srcset(item, 121, item.cols, item.rows)}
                            alt={item.url}
                            loading="lazy"
                            onClick={() => window.open(item)}
                            style={{ cursor: "pointer" }}
                          />
                        </ImageListItem>
                      ) : (
                        ""
                      )}
                    </div>
                  ))}
                </ImageList>
              </div>
            </Skeleton>
          </Card>
        ) : filePDF ? (
          <embed
            src={filePDF}
            width="100%"
            height="500px"
            type="application/pdf"
          />
        ) : (
          <p>Không thấy thông tin</p>
        )}
      </Modal>
      <Modal
        title="Phiếu yêu cầu xét nghiệm"
        open={openModalOrderByTest}
        footer={null}
        onCancel={() => setOpenModalOrderByTest(false)}
        width={1500}
        className="modal-scroll"
        centered
      >
        <OrderByTestModal
          id={patient_id}
          handleCancel={handleCancel}
          fetchData={fetchData}
          random={random}
        />
      </Modal>
      <ModalDelete
        open={openModalDeleteOrderByTest}
        cancel={handleCancel}
        form={formDelete}
        handleSubmit={handleDelete}
        loading={loading}
        title={"Xóa"}
      />
    </>
  );
};

export default ListOrderByTest;
