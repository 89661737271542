import { Button, Modal, Table, Spin, message, Form } from "antd";
import { useEffect, useRef, useState } from "react";
import { ExclamationCircleOutlined, WechatOutlined } from "@ant-design/icons";
import { api } from "src/services";
import { DoctorReferrersDetail } from "src/views";
import { contains } from "src/utils";
import {
  AvatarName,
  BreadcrumbComponent,
  CardProfile,
  IconDelete,
  IconViewDetail,
  ModalDelete,
  SearchInput,
  SpanMailTo,
  SpanPhone,
} from "src/components";
import { DoctorReferrersModal } from "src/views";
import { useDispatch, useSelector } from "react-redux";
import { allActions } from "src/redux";

export default function DoctorReferrers() {
  const [listPatients, setListPatients] = useState([]);
  const [loading, setLoading] = useState(false);

  const [tableParams, setTableParams] = useState<any>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  const [isModalOpenDetail, SetIsModalOpenDetail] = useState(false);
  const [props, setDataProps] = useState(false);
  const formRef = useRef<any>(null);
  const [isOpenModalCreate, setIsOpenModalCreate] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [searchQuery, setSearchQuery]: any = useState("");
  const [isModalOpenProfile, SetIsModalOpenProfile] = useState(false);
  const [isOpenModalDelete, setIsOpenModalDelete] = useState(false);
  const [dataProfile, SetDataProfile] = useState("");
  const [formDelete] = Form.useForm();
  const [data, setData] = useState([]);
  const dispatch = useDispatch();

  let userId = null;
  const userData = useSelector((state:any) => state.user);

  const userActiveMessage = useSelector((state:any) => state.option);

  if(userData) {
      userId  = userData?.id;
  }

  useEffect(() => {
    fetchData();
  }, [searchQuery]);

  // const fetchData = async (page?: 1, pageSize?: 10) => {
  //   try {
  //     setLoading(true);
  //     const { data: rPatitent, meta }: any = await api.getAllDoctorReferrers(
  //       pageSize,
  //       page,
  //       contains.doctorType,
  //       searchQuery
  //     );

  //     if (rPatitent) {
  //       let patientArr = rPatitent.map((el: any) => ({ key: el.id, ...el }));
  //       setTableParams({
  //         ...tableParams,
  //         pagination: {
  //           current: page,
  //           pageSize: meta.per_page,
  //           total: meta.total,
  //         },
  //       });
  //       setListPatients(patientArr);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const fetchData = async () => {
    try {
      setLoading(true);
      const { data: rPatitent }: any = await api.getDoctorsReferrersByUser();
      if (rPatitent) {
        let patientArr = rPatitent.map((el: any) => ({ key: el.id, ...el }));
        setTableParams({});
        setListPatients(patientArr);
        setData(rPatitent);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const filteredData = data.filter(
    (category) =>
      category.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      category.phone.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const handleNameClickProfile = async (record) => {
    SetIsModalOpenProfile(true);
    SetDataProfile(record);
  };

  const handleChat = async(user:any)=>{
    dispatch(allActions.option.saveOption(user));
  }

  const columns = [
    {
      title: "STT",
      dataIndex: "id",
      key: "id",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Tên",
      dataIndex: "avatar",
      key: "avatar",
      render: (avatar, record) => (
        <div>
          <AvatarName
            avatar={avatar}
            name={record?.name}
            profileDoctor={() => handleNameClickProfile(record)}
            viewDetail={() => handleNameClickProfile(record)}
          />
        </div>
      ),
    },
    {
      title: "Chuyên khoa",
      dataIndex: "specialist",
      key: "specialist",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (text: string, record: any) => <SpanMailTo mail={text} />,
    },
    {
      title: "Điện thoại",
      dataIndex: "phone",
      key: "phone",
      render: (text: string, record: any) => <SpanPhone phone={text} />,
    },
    {
      title: "Địa chỉ",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Giới tính",
      dataIndex: "gender",
      key: "gender",
      render: (text: string, record: any) => (
        <span>{text === "male" ? "Nam" : text === "female" ? "Nữ" : ""}</span>
      ),
    },
    {
      title: "Hành động",
      render: (text: string, record: any) => (
        <div style={{display: 'flex',flexWrap:'wrap', justifyContent:'center', gap:'1px', alignItems:'center'}}>
          <span 
            className="icon-container"  
            title="Liên hệ"
            onClick={()=> handleChat(record)}
          >
            <WechatOutlined />
          </span>
          <IconViewDetail onClick={() => handleNameClickProfile(record)} />
        </div>
      ),
    },
  ];

  const handleCancelDetail = () => {
    SetIsModalOpenDetail(false);
  };
  const handleCancel = () => {
    setIsOpenModalDelete(false);
  };

  // const rowSelection = {
  //   onChange: (selectedRowKeys: any, selectedRows: any) => {
  //     setSelectedRows(selectedRows.map((el: any) => el.id));
  //   },
  //   onSelect: (record: any, selected: any, selectedRows: any) => {
  //     setSelectedRows(selectedRows.map((el: any) => el.id));
  //   },
  //   onSelectAll: (selected: any, selectedRows: any, changeRows: any) => {
  //     setSelectedRows(selectedRows.map((el: any) => el.id));
  //   },
  // };

  const hanndleDelete = async (values) => {
    try {
      setLoading(true);
      const response: any = await api.deleteMyPatient(values.id);
      if (response.error) {
        message.error(response.message);
      } else {
        message.success(response.message);
      }
    } catch (error) {
      console.log(error);
    } finally {
      handleCancel();
      fetchData();
      setLoading(false);
    }
  };

  const onAddFriend = async () => {
    try {
      const res = await api.addFreindReference(searchQuery, userId, '');
    } catch (error) {
      console.error(error);
    }
  };

  const handleTableChange = () => {
    fetchData();
  };

  const handleCancelProfile = () => {
    SetIsModalOpenProfile(false);
  };
  const handleSubmit = async (values: any) => {
    let formData = new FormData();
    formData.append("doctor_id", values.doctor_id);
    formData.append("patient_id", values.patient_id);
    formData.append("note", values.note);
    console.log(values);
    try {
      setLoading(true);
      const response: any = await api.createDoctorReferrers(formData);
      if (response.error) {
        message.error(response.message);
      } else {
        message.success(response.message);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      setIsOpenModalCreate(false);
      fetchData();
    }
  };
  const breadcrumbItems = [
    { path: "/", name: "Dashboard" },
    { path: "/doctor-referrers", name: "Bác sĩ giới thiệu" },
  ];

  return (
    <div className="admin-table">
      <div>
        <BreadcrumbComponent items={breadcrumbItems} />
      </div>
      <div className="flex-fill">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: ".5rem",
          }}
        ></div>
        <Modal
          title="Giới thiệu bệnh nhân cho bác sĩ khác"
          open={isOpenModalCreate}
          width={800}
          onCancel={() => setIsOpenModalCreate(false)}
          footer={null}
          centered
          className="modal-scroll"
        >
          <DoctorReferrersModal handleSubmit={handleSubmit} />
        </Modal>
        <div className="menu_control_add">
          <div className="left">
            <Button
              onClick={onAddFriend}
              type="primary"
            >
              Thêm
            </Button>
            <SearchInput
              onSearch={(value) => {
                setSearchQuery(value);
              }}
              title={"Tìm kiếm"}
              width={250}
            />
          </div>
          <div
            className="button_add"
            title="Thêm giới thiệu bác sĩ"
            onClick={() => {
              setIsOpenModalCreate(true);
            }}
          >
            <h4 style={{ cursor: "pointer" }}>Giới thiệu mới</h4>
          </div>
        </div>
        <Table
          // rowSelection={{ ...rowSelection }}
          columns={columns}
          dataSource={filteredData}
          pagination={tableParams.pagination}
          loading={loading}
          onChange={handleTableChange}
        />
        <Modal
          title="Thông tin bác sĩ"
          centered
          open={isModalOpenDetail}
          onOk={handleCancelDetail}
          onCancel={() => SetIsModalOpenDetail(false)}
          footer={null}
          confirmLoading={loading}
          width={750}
          className="modal-scroll"
        >
          <Spin spinning={loading}>
            <DoctorReferrersDetail rPatitent={props} />
          </Spin>
        </Modal>
        <CardProfile
          doctorArr={dataProfile}
          isModalOpenProfile={isModalOpenProfile}
          setIsModalCancelProfile={() => SetIsModalOpenProfile(false)}
        />
        <ModalDelete
          open={isOpenModalDelete}
          cancel={handleCancel}
          title={"Xóa"}
          form={formDelete}
          handleSubmit={hanndleDelete}
          loading={loading}
        />
        .
      </div>
    </div>
  );
}
